<template>
    <!-- TODO: Multifield implementation
    <div>
        <component
            :is="formie.getComponent('formField')"
            v-if="field.fields"
            :field="field"
        >
            <div class="formie-page__row">
                <div
                    v-for="subField in field.fields"
                    :key="`${field.id}-${subField.id}`"
                    class="formie-page__row__field formie-page--subfield"
                >
                    {{ subField.handle }}
                    {{ `Input${subField.displayName}` }}
                    <component
                        :is="formie.getField(`Input${subField.displayName}`)"
                        v-model="value[subField.handle]"
                        :field="subField"
                    />
                </div>
            </div>
        </component>
    </div>-->

    <div>
        <!-- Simple Vue Datepicker implementation -->
        <component
            :is="getFormieComponent('formField')"
            :field="field"
        >
            <VueDatePicker
                ref="$vueDatePickerRef"
                v-model="value"
                :clearable="false"
                v-bind="getVueDatePickerBindings"
                :min-date="minDate"
                :max-date="maxDate"
                :action-row="{ showCancel: false, showPreview: false}"
                :month-change-on-scroll="false"
                :format
                :disabled-week-days="disabledWeekDays"
                auto-apply
            />
        </component>
    </div>
</template>

<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

// Define props
const props = defineProps({
    field: {
        type: Object,
        required: true,
    }
});

// Props to refs
const { field } = toRefs(props);

const value = defineModel({
    type: [String, Object],
    default: () => ({}),
    set: newValue => setFormattedDate(newValue),
});

const fieldLabels = computed(() => field.value.fields.map(( { label } ) => label));

const format = computed(() => {
    if (fieldLabels.value?.includes('Date')) {
        return 'dd-MM-yyyy';
    }

    if (fieldLabels.value?.includes('Time')) {
        return 'HH:mm';
    }

    return 'dd-MM-yyyy HH:mm';
});

// Template refs
const $vueDatePickerRef = ref(null);

// Get the VueDatePicker bindings based on datetime, date, or time.
const getVueDatePickerBindings = computed(() => {
    const bindings = {};

    if (fieldLabels.value?.includes('Date') && fieldLabels.value?.includes('Time')) {
        bindings['time-picker-inline'] = true;
    } else if (fieldLabels.value?.includes('Date')) {
        bindings['enable-time-picker'] = false;
        bindings['enable-seconds'] = false;
    } else if (fieldLabels.value?.includes('Time')) {
        bindings['month-picker'] = false;
        bindings['time-picker'] = true;
        bindings['enable-seconds'] = false;
    }

    return bindings;
});

const minDate = computed(() => {
    if (field.value?.minDate) {
        return new Date(field.value.minDate);
    }

    return null;
});

const maxDate = computed(() => {
    if (field.value?.maxDate) {
        return new Date(field.value.maxDate);
    }

    return null;
});

// Formie availableDaysOfWeek looks like: ["zondag","maandag","dinsdag","woensdag","donderdag","vrijdag","zaterdag"]
// VueDatePicker disabled-week-days expects (0 = Sunday, 6 = Saturday): [0,1,2,3,4,5,6]
// So we need to convert the Formie available array to the VueDatePicker disabled array.
const disabledWeekDays = computed(() => {
    if (!field.value?.availableDaysOfWeek) {
        return [];
    }

    const daysOfWeek = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];

    return daysOfWeek.reduce((disabledDays, day, index) => {
        if (!field.value?.availableDaysOfWeek?.includes(day)) {
            disabledDays.push(index);
        }
        return disabledDays;
    }, []);
});

const setFormattedDate = (_value) => {
    const date = new Date(_value);

    // In case of the time picker, we just want to return the time since it's not a valid date.
    if (isNaN(date)) {
        return _value;
    } else {
        return date.toISOString();
    }
};

const bindFieldBindingsToVueDatePicker = () => {
    const inputEl = $vueDatePickerRef.value?.$el?.querySelector('input');

    if (inputEl) {
        const bindings = getField(field.value).getInputBindings();
        // Remove placeholder since it's not supported by VueDatePicker
        delete bindings.placeholder;

        inputEl.setAttribute('novalidate', '');
        if (bindings.required) {
            inputEl.setAttribute('required', '');
        }
        delete bindings.required;
        Object.entries(bindings)
            .forEach(([key, val]) => inputEl.setAttribute(key, val));
    }
};

onMounted(() => {
    bindFieldBindingsToVueDatePicker();
});
</script>

<style>
:root {
    /*General*/
    --dp-font-family: -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, oxygen, ubuntu, cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --dp-border-radius: 0; /*Configurable border-radius*/
    --dp-cell-border-radius: 4px; /*Specific border radius for the calendar cell*/
    --dp-common-transition: all .1s ease-in; /*Generic transition applied on buttons and calendar cells*/

    /*Sizing*/
    --dp-button-height: 35px; /*Size for buttons in overlays*/
    --dp-month-year-row-height: 35px; /*Height of the month-year select row*/
    --dp-month-year-row-button-size: 35px; /*Specific height for the next/previous buttons*/
    --dp-button-icon-height: 20px; /*Icon sizing in buttons*/
    --dp-cell-size: 35px; /*Width and height of calendar cell*/
    --dp-cell-padding: 5px; /*Padding in the cell*/
    --dp-common-padding: 10px; /*Common padding used*/
    --dp-input-icon-padding: 35px; /*Padding on the left side of the input if icon is present*/
    --dp-input-padding: .6875rem 1rem; /*Padding in the input*/
    --dp-menu-min-width: 260px; /*Adjust the min width of the menu*/
    --dp-action-buttons-padding: 2px 5px; /*Adjust padding for the action buttons in action row*/
    --dp-row-margin: 5px 0; /*Adjust the spacing between rows in the calendar*/
    --dp-calendar-header-cell-padding: .5rem; /*Adjust padding in calendar header cells*/
    --dp-two-calendars-spacing: 10px; /*Space between multiple calendars*/
    --dp-overlay-col-padding: 3px; /*Padding in the overlay column*/
    --dp-time-inc-dec-button-size: 32px; /*Sizing for arrow buttons in the time picker*/
    --dp-menu-padding: 6px 8px; /*Menu padding*/

    /*Font sizes*/
    --dp-font-size: 1rem; /*Default font-size*/
    --dp-preview-font-size: .8rem; /*Font size of the date preview in the action row*/
    --dp-time-font-size: .8rem; /*Font size in the time picker*/

    /*Transitions*/
    --dp-animation-duration: .1s; /*Transition duration*/
    --dp-menu-appear-transition-timing: cubic-bezier(.4, 0, 1, 1); /*Timing on menu appear animation*/
    --dp-transition-timing: ease-out; /*Timing on slide animations*/
}
</style>
